const FOOTER_DATA = (lang) => {
  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const data = {
    uz: {
      textBrend1: `ALITRANS O'zbekiston, Rossiya, Qozog'iston, Qirg'iziston, Janubi-Sharqiy Osiyo va Yevropaning o'zaro va boshqa xorijiy davlatlar bilan o'sib borayotgan tashqi savdosiga faol xizmat ko'rsatishga yo'naltirilgan.`,
      textBrend2: `Bog'laning va biz sizning biznesingizga yordam beramiz`,
      set: `biz ijtimoiy tarmoqlardamiz`,
      recentPost:"Oxirgi post",
      title1: "Bizning missiyamiz",
      title2: "Bizning qarashimiz",
      text1: "Xar qanday yomon sharoit kelsa ham, chiqib keta olamiz",
      text2: "Biz doimiy ravishda transportlamizni yangilab boramiz",
      date: date,
      gallery: "Bizning fotostrimimiz",
    },
    ru: {
      textBrend1: `Компания АЛИТРАНС ориентирована на активное обслуживание растущей внешней торговли Узбекистана, России, Казахстана, Кыргызстана, Юго-Восточной Азии и Европы как между собой, так и с другими зарубежными странами.`,
      textBrend2: `Свяжитесь с нами, и мы поможем вашему бизнесу`,
      set: `Мы в социальных сетях`,
      recentPost:"Недавний пост",
      title1: "Наша миссия",
      title2: "Наше видение",
      text1: "Мы можем выйти, даже если наступят плохие условия",
      text2: "Мы постоянно обновляем наши автомобили новыми",
      date: date,
      gallery: "Наш фотопоток",
    },
    en: {
      textBrend1: `ALITRANS is focused on active servicing of the growing foreign trade of Uzbekistan, Russia, Kazakhstan, Kyrgyzstan, Southeast Asia and Europe both among themselves and with other foreign countries.`,
      textBrend2: `Get in Touch And We'll Help Your Business`,
      set: `We're Social`,
      recentPost:"Recent Post",
      title1: "Our Mission",
      title2: "Our Vision",
      text1: "We can get out even if bad conditions come",
      text2: "We are constantly updating our transportation",
      date: date,
      gallery: "Our Photostream",
    },
  };
  return data[lang] || data.ru;
};
export default FOOTER_DATA;
