import { useAppContext } from "contexts/AppContext";
import BREADCRUMBS_DATA from "i18n/components/breadcrumbs/breadcrumbs.i18n";
import FOOTER_DATA from "i18n/components/footer/footer.i18n";
import GET_LANG_DATA from "i18n/header.i18";
import React from "react";

const Footer = () => {
  const { state, dispatch } = useAppContext();
  const i18n = FOOTER_DATA(state.lang);
  const i18nl = GET_LANG_DATA(state.lang);
  const i18nb = BREADCRUMBS_DATA(state.lang);
  return (
    <>
      {/* footer start */}
      <footer className="wide-tb-70 bg-light-gray pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 ">
              <div className="logo-footer">
                <a href="/">
                  <img src="images/trans.png" alt="" />
                </a>
              </div>
              <p style={{ fontSize: "12px" }}>{i18nb.descAbout}</p>
            </div>

            <div className="col-lg-4 col-md-6 ">
              {/* <h3 className="footer-heading">{i18n.recentPost}</h3> */}
              <div className="blog-list-footer">
                <ul>
                  <li style={{ margin: "6px", padding: "0" }}>
                    <a href="/">{i18nl.home}</a>
                  </li>
                  <li style={{ margin: "6px", padding: "0" }}>
                    <a href="/about">{i18nl.about}</a>
                  </li>
                  <li style={{ margin: "6px", padding: "0" }}>
                    <a href="/contact">{i18nl.contact}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 ">
              <p>{i18n.textBrend2}</p>

              <h3 className="footer-heading">{i18n.set}</h3>
              <div className="social-icons">
                <a href="https://t.me/Ibragimov1995" target="_blank">
                  <i className="icofont-telegram"></i>
                </a>
                <a href="mailto:alitrans82@mail.ru" target="_blank">
                  <i className="icofont-ui-email"></i>
                </a>
                <a href="https://wa.me/998936661000" target="_blank">
                  <i className="icofont-whatsapp"></i>
                </a>
                {/* https://t.me/Ibragimov1995 */}
                <a href="mailto:xolmuxxammad1995@gmail.com" target="_blank">
                  <i className="icofont-google-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
