const WHATOUR_DATA = (lang) => {
  const data = {
    uz: {
      whatOur: "Bizning mijozlarimiz",
      saying: "nima deyishadi",
      name1: "Muhammad",
      text1: `Siz olib kelgan mevalar juda yaxshi holatda. Rahmat! Siz bilan bog'langanimdan xursandman`,
      name2: "Komila",
      text2: `Yetkazib berganingiz uchun rahmat. Men o'ylaganimdan ham yaxshiroq. Har doim shunday qiling.`,
      name3: "Lola",
      text3: `Juda yaxshi. Ishingiz menga yoqdi. Ajoyib👍 Men bilan yana ishlay olasizmi?`,
    },
    ru: {
      whatOur: "Что говорят",
      saying: "наши клиенты",
      name1: "Магамед",
      text1: `Фрукты, которые вы принесли, в очень хорошем состоянии. Спасибо! Я рад обратиться к вам`,
      name2: "Камилла",
      text2: `Спасибо за вашу доставку. Лучше, чем я думал. Всегда делай это. Прекрасно! Товар пришел накануне.`,
      name3: "Лола",
      text3: `Очень хороший. Мне понравилась твоя работа. Просто прекрасно👍 Можешь снова работать со мной?`,
    },
    en: {
      whatOur: "What Our",
      saying: "Customers Saying",
      name1: "Magamed",
      text1: `The fruit you brought is in very good condition. Thank you! I'm glad to contact you`,
      name2: "Kamilla",
      text2: `Thanks for your delivery. Better than I thought. Always do it. Perfectly! The product arrived the day before.`,
      name3: "Lola",
      text3: `Very good. I liked your work. Just great👍 Can you work with me again?`,
    },
  };
  return data[lang] || data.ru;
};
export default WHATOUR_DATA;
