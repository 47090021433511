import emailjs from "@emailjs/browser";
import { useAppContext } from "contexts/AppContext";
import REQUEST_DATA from "i18n/components/request/request.i18n";
import GET_LANG_DATA from "i18n/header.i18";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
const Forms = ({ data }) => {
  const { state, dispatch } = useAppContext();
  const i18n = REQUEST_DATA(state.lang);
  const i18na = GET_LANG_DATA(state.lang);
  const form = useRef();
  const [value, setValue] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    message: "",
  });
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a71ke1h",
        "template_lquxfdo",
        form.current,
        "0sXoSFBgWhxF6XBXY"
      )
      .then(
        (result) => {
          console.log("success test: ", result.text);
          toast.success(i18na.sucsess);
        },
        (error) => {
          toast.error(error.text);
        }
      );
    setValue({
      user_name: "",
      user_email: "",
      user_number: "",
      message: "",
    });
  };
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <form
        className={`${
          data === "contact" ? "col rounded-field" : "rounded-field"
        }`}
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="form-row mb-4">
          <div className={`${data === "contact" ? "col-12" : "col"}`}>
            <input
              type="text"
              name="user_name"
              value={value.user_name}
              required
              className="form-control mb-3"
              placeholder={i18n.pName}
              onChange={handleChange}
            />
            {data != "contact" ? (
              <input
                type="text"
                name="user_email"
                value={value.user_email}
                required
                className="form-control mb-3"
                placeholder={i18n.pEmail}
                onChange={handleChange}
              />
            ) : null}
            <input
              type="text"
              name="user_number"
              value={value.user_number}
              required
              className="form-control"
              placeholder={i18n.pPhone}
              onChange={handleChange}
            />
          </div>
          <div className={`${data === "contact" ? "col-12 mt-3" : "col"}`}>
            <textarea
              rows={`${data === "contact" ? "10" : "7"}`}
              name="message"
              placeholder={i18n.pMessage}
              onChange={handleChange}
              value={value.message}
              required
              className="form-control"
            ></textarea>
          </div>
        </div>
        <div className="form-row">
          <div className="col d-flex justify-content-center pt-3">
            <button
              type="submit"
              data-toggle="modal"
              data-target={`${data === "contact" ? "" : "#request_popup"}`}
              className="form-btn btn-theme bg-orange"
            >
              {i18n.sendMessage} <i className="icofont-rounded-right"></i>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Forms;
