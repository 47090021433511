const ABOUTUS_DATA = (lang) => {
  const data = {
    uz: {
      weAre: "BIZ KIMMIZ",
      about1: "Alitrans",
      about2: "haqida",
      title: "Biz haqimizda",
      text1: `Tarqatish yoki joylashtirishni xohlaysizmi, aniqlanadi
      ta'minot yoki to'liq ta'minot zanjiri yechim, biz
      bu yerda siz uchun.`,
      history: "Bizning Tarix",
      text2: `Quvvatlangan bozorlarni birgalikda boshqaring
      tarmoqlarni ulang va o'ynang. B2C ni dinamik uzoqlashtirish
      o'ziga foydadan keyin foyda.`,
      text3: `Biz nafaqat etkazib beruvchilarni, balki ularni mikro darajada boshqaramiz.
      Bizda konsultativ, individual yondashuv mavjud. Dramatik tarzda
              mijozlarga yo'naltirilgan konvergentsiyani tasavvur qiling
      inqilobiy ROI rejimida.`,
    },
    ru: {
      weAre: "КТО МЫ ЕСТЬ",
      about1: "О",
      about2: "Алитранс",
      title: "О нас",
      text1: `Определяется, хотите ли вы распространять или размещать
      поставка или комплексное решение цепочки поставок, мы
      здесь для вас.`,
      history: "Наша история",
      text2: `Управляйте поддерживаемыми рынками вместе
      подключайте сети и играйте. Динамическое удаление B2C
      выгода за выгодой для себя.`,
      text3: `Мы управляем не только поставщиками, но и ими на микроуровне.
      У нас консультативный, индивидуальный подход. Драматически
               представьте конвергенцию, ориентированную на клиента
      в революционном режиме ROI.`,
    },
    en: {
      weAre: "WHO WE ARE",
      about1: "About",
      about2: "Alitrans",
      title: "About Us",
      text1: `It is determined whether you want to distribute or deploy
      supply or complete supply chain solution, we
      here for you.`,
      history: "Our History",
      text2: `Manage supported markets together
      connect networks and play. Dynamic removal of B2C
      benefit after benefit to oneself.`,
      text3: `We manage not only suppliers, but also them at the micro level.
      We have a consultative, individual approach. Dramatically
                imagine customer centric convergence
      in revolutionary ROI mode.`,
    },
  };
  return data[lang] || data.ru;
};
export default ABOUTUS_DATA;
