import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import PageContent from "./content";
import RequestModal from "components/RequestModal";

const Home = () => {
  return (
    <>
      <Header />
      <PageContent />
      <Footer />
      <RequestModal />
    </>
  );
};
export default Home;
