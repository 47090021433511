const GET_LANG_DATA = (lang) => {
  const data = {
    uz: {
      home: "Bosh sahifa",
      about: "Biz haqimizda",
      faq: "TSS",
      contact: "Bog'lanish",
      counter: "Uzbekcha",
      shortCoun: "UZ",
      flag: "images/uzbekistan.svg",
      phone: "Telefon raqami",
      email: "Elektron pochta",
      adress: "Manzil",
      sucsess:"Xabar yuborildi...",
      adress2:"Toshkent viloyati, Angren shahri, NODIRABEGIM ko‘chasi, 5-uy",
      request: "So'rov junatish",
    },
    ru: {
      home: "Главная",
      about: "О нас",
      faq: "Вопросы-Ответы",
      contact: "Связаться с нами",
      counter: "Русский",
      shortCoun: "РУ",
      flag: "images/BevelledRussia.svg",
      phone: "Телефонный номер",
      email: "Эл. адрес",
      adress: "Адрес",
      sucsess:"Сообщение отправлено ...",
      adress2:"Ташкентская область, г. Ангрен, улица НОДИРАБЕГИМ 5",
      request: "Отправить запрос",
    },
    en: {
      home: "Home",
      about: "About Us",
      faq: "FAQ",
      contact: "Contact Us",
      counter: "English",
      shortCoun: "EN",
      flag: "images/us.svg",
      phone: "Phone Number",
      email: "Email",
      adress: "Adress",
      sucsess:"Message sent ...",
      adress2:"Tashkent region, Angren city, 5 NODIRABEGIM street",
      request: "REQUEST QUOTE",
    },
  };
  return data[lang] || data.ru;
};
export default GET_LANG_DATA;
