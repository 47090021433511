import { useAppContext } from "contexts/AppContext";
import GET_LANG_DATA from "i18n/header.i18";
import React from "react";

const Breadcrumbs = (props) => {
  const { state, dispatch } = useAppContext();
  const i18n = GET_LANG_DATA(state.lang);
  return (
    <>
      <div className="slider bg-navy-blue bg-scroll pos-rel breadcrumbs-page">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <i className="icofont-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">{i18n.home}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.title}
              </li>
            </ol>
          </nav>

          <h1>{props.title}</h1>
          <div className="breadcrumbs-description">{props.description}</div>
        </div>
      </div>
    </>
  );
};
export default Breadcrumbs;
