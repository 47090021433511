import { useAppContext } from "contexts/AppContext";
import ABOUTUS_DATA from "i18n/components/aboutUs/aboutUs.i18n";
import CHOOSE_DATA from "i18n/components/chooseUs/chooseUs.i18n";
import FOOTER_DATA from "i18n/components/footer/footer.i18n";
import INFOCOST_DATA from "i18n/components/infoCost/cost.i18n";
import REQUEST_DATA from "i18n/components/request/request.i18n";
import WHATOUR_DATA from "i18n/components/whatOur/whatour.i18n";
import GET_LANG_DATA from "i18n/header.i18";
import React from "react";
import randomcolor from "randomcolor";
import { CUT_STRINGS } from "utils/cutString";

const Content = () => {
  const { state, dispatch } = useAppContext();
  const i18n = ABOUTUS_DATA(state.lang);
  const i18na = GET_LANG_DATA(state.lang);
  const i18nch = CHOOSE_DATA(state.lang);
  const i18nk = INFOCOST_DATA(state.lang);
  const i18nr = REQUEST_DATA(state.lang);
  const i18nw = WHATOUR_DATA(state.lang);
  const i18nf = FOOTER_DATA(state.lang);

  return (
    <>
      <main id="body-content">
        <section className="bg-light-gray wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div className="img-business-man">
                <img src="images/courier-man.png" alt="" />
              </div>
              <div className="col-md-6 ml-auto">
                <div className="">
                  <span>{i18n.weAre}</span>
                  <h1 className="heading-main text-left mb-5">{i18n.title}</h1>
                </div>

                <p className="lead fw-5 txt-blue">
                  {i18n.text1}
                  <br />
                  {i18nf.textBrend1}
                </p>

                <div className="mt-5">
                  {/* <ul
                    className="nav nav-pills theme-tabbing mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        {i18n.title}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        {i18n.history}
                      </a>
                    </li>
                  </ul> */}
                  <div
                    className="tab-content theme-tabbing"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <p>{i18n.text2} </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <p>{i18n.text2} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>{i18nch.features}</span>
                  {i18nch.choose}
                </h1>
              </div>

              <div className="col-12 col-lg-4">
                <div className="icon-box-2 mb-4">
                  <div className="media">
                    <div className="service-icon">
                      <i className="icofont-check-circled"></i>
                    </div>
                    <div className="service-inner-content media-body">
                      <h4 className="h4-md">{i18nch.title1}</h4>
                      <p>{i18nch.text1}</p>
                    </div>
                  </div>
                </div>
                <div className="icon-box-2">
                  <div className="media">
                    <div className="service-icon">
                      <i className="icofont-check-circled"></i>
                    </div>
                    <div className="service-inner-content media-body">
                      <h4 className="h4-md">{i18nch.title2}</h4>
                      <p>{i18nch.text2}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <img src="images/truck_front.jpg" alt="" />
              </div>

              <div className="col-12 col-lg-4">
                <div className="icon-box-2 mb-4">
                  <div className="media">
                    <div className="service-icon">
                      <i className="icofont-check-circled"></i>
                    </div>
                    <div className="service-inner-content media-body">
                      <h4 className="h4-md">{i18nch.title3}</h4>
                      <p>{i18nch.text3}</p>
                    </div>
                  </div>
                </div>
                <div className="icon-box-2">
                  <div className="media">
                    <div className="service-icon">
                      <i className="icofont-check-circled"></i>
                    </div>
                    <div className="service-inner-content media-body">
                      <h4 className="h4-md">{i18nch.title4}</h4>
                      <p>{i18nch.text4}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wide-tb-100 bg-scroll counter-bg pos-rel">
          <div className="bg-overlay blue opacity-50"></div>
          <div className="container">
            <div className="row">
              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 ">
                <p>
                  <i className="icofont-google-map"></i>
                </p>
                <span className="counter">1</span>
                <div>{i18nk.text1}</div>
              </div>

              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 ">
                <p>
                  <i className="icofont-globe"></i>
                </p>
                <span className="counter">110</span>
                <span>+</span>
                <div>{i18nk.text2}</div>
              </div>

              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>

              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 ">
                <p>
                  <i className="icofont-vehicle-delivery-van"></i>
                </p>
                <span className="counter">20</span>
                <span>+</span>
                <div>{i18nk.text3}</div>
              </div>

              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 s">
                <p>
                  <i className="icofont-umbrella-alt"></i>
                </p>
                <span className="counter">2340</span>
                <div>{i18nk.text4}</div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-100 bg-fixed free-quote pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-7">
                <div className="free-quote-form">
                  <h1 className="heading-main mb-4">
                    <span>{i18nr.request}</span>
                    {i18nr.free}
                  </h1>

                  <form
                    action="#"
                    method="post"
                    noValidate="novalidate"
                    className="col rounded-field"
                  >
                    <div className="form-row mb-4">
                      <input
                        type="text"
                        name="name"
                        required
                        className="form-control"
                        placeholder={i18nr.pName}
                      />
                    </div>
                    <div className="form-row mb-4">
                      <input
                        type="text"
                        name="email"
                        required
                        className="form-control"
                        placeholder={i18nr.pEmail}
                      />
                    </div>
                    <div className="form-row mb-4">
                      <select
                        title="Please choose a package"
                        required=""
                        name="package"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="">{i18nr.transport}</option>
                        <option value="Type 1">{i18n.transType}</option>
                      </select>
                    </div>
                    <div className="form-row mb-4">
                      <select
                        title="Please choose a package"
                        required=""
                        name="package"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="">{i18nr.freight}</option>
                        <option value="Type 1">{i18nr.fruit}</option>
                        <option value="Type 2">{i18nr.technics}</option>
                        <option value="Type 3">{i18nr.wood}</option>
                        <option value="Type 4">{i18nr.machine}</option>
                        <option value="Type 5">{i18nr.pipe}</option>
                        <option value="Type 6">{i18nr.instruments}</option>
                        <option value="Type 7">{i18nr.water}</option>
                        <option value="Type 8">{i18nr.car}</option>
                      </select>
                    </div>
                    <div className="form-row mb-4">
                      <textarea
                        rows="7"
                        placeholder={i18nr.pMessage}
                        className="form-control"
                      ></textarea>
                    </div>
                    <div className="form-row text-center">
                      <button
                        type="submit"
                        className="form-btn mx-auto btn-theme bg-orange"
                      >
                        {i18nr.title}
                        <i className="icofont-rounded-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>{i18nw.whatOur}</span>
                  {i18nw.saying}
                </h1>
              </div>
              <div className="col-sm-12">
                <div
                  className="owl-carousel owl-theme"
                  id="home-client-testimonials"
                >
                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        <div
                          className="rounded-circle bg-navy-blue d-flex align-items-center justify-content-center"
                          style={{
                            width: "120px",
                            height: "120px",
                            background: randomcolor(),
                            border: "1px solid white",
                          }}
                        >
                          <h1 style={{ color: "white", fontSize: "65px" }}>
                            {CUT_STRINGS(i18nw.name1, 1)}
                          </h1>
                          {/* <img src="images/team_1.jpg" alt="" /> */}
                        </div>
                        <div className="client-inner-content media-body">
                          <p>{i18nw.text1} </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">{i18nw.name1}</cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        <div
                          className="rounded-circle bg-navy-blue d-flex align-items-center justify-content-center"
                          style={{
                            width: "120px",
                            height: "120px",
                            background: randomcolor(),
                            border: "1px solid white",
                          }}
                        >
                          <h1 style={{ color: "white", fontSize: "65px" }}>
                            {CUT_STRINGS(i18nw.name2, 1)}
                          </h1>
                          {/* <img src="images/team_2.jpg" alt="" /> */}
                        </div>
                        <div className="client-inner-content media-body">
                          <p>{i18nw.text2}, </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">{i18nw.name2}</cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        <div
                          className="rounded-circle bg-navy-blue d-flex align-items-center justify-content-center"
                          style={{
                            width: "120px",
                            height: "120px",
                            background: randomcolor(),
                            border: "1px solid white",
                          }}
                        >
                          <h1 style={{ color: "white", fontSize: "65px" }}>
                            {CUT_STRINGS(i18nw.name3, 1)}
                          </h1>
                          {/* <img src="images/team_3.jpg" alt="" /> */}
                        </div>
                        <div className="client-inner-content media-body">
                          <p>{i18nw.text3} </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">{i18nw.name3}</cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="map-bg relative">
          {/* <div className="contact-details row d-flex">
            <div className="col">
              <h4>Uzbekistan</h4>
              <a href="tel:+998936661000">
                <i className="icofont-phone"></i> +998 93 666 1000
              </a>
              <div className="text-nowrap">
                <i className="icofont-google-map"></i>{" "}
                <a >{i18na.adress2}</a>
              </div>
              <div className="text-nowrap">
                <i className="icofont-email"></i>{" "}
                <a href="mailto:alitrans82@mail.ru">alitrans82@mail.ru</a>
              </div>
            </div>
          </div> */}
          <div id="map-holder" className="pos-rel">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1504.96731123333!2d70.05579906715273!3d41.02668624946594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38afebbee6870f37%3A0xd426c7d70c32b714!2sAlitrans%20Logistics%20company!5e0!3m2!1sen!2s!4v1651319687320!5m2!1sen!2s"
                width="600"
                height="450"
                style={{ border: "0", width: "100%" }}
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
