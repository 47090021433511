import { useAppContext } from 'contexts/AppContext';
import ABOUTUS_DATA from 'i18n/components/aboutUs/aboutUs.i18n';
import CHOOSE_DATA from 'i18n/components/chooseUs/chooseUs.i18n';
import GOODNES_DATA from 'i18n/components/goodnes/goodnes.i18n';
import INFOCOST_DATA from 'i18n/components/infoCost/cost.i18n';
import REQUEST_DATA from 'i18n/components/request/request.i18n';
import React from 'react';

const Content = () => {
	const { state, dispatch } = useAppContext();
	const i18n = ABOUTUS_DATA(state.lang);
	const i18ng = GOODNES_DATA(state.lang);
	const i18nk = INFOCOST_DATA(state.lang);
	const i18nch = CHOOSE_DATA(state.lang);
	const i18nr = REQUEST_DATA(state.lang);
	return (
		<>
			<main id='body-content'>
				<section className='wide-tb-80'>
					<div className='container pos-rel'>
						<div className='row align-items-center'>
							<div className='col-md-6 '>
								<h2 className='mb-4 fw-7 txt-blue'>
									{i18n.about1}{' '}
									<span className='fw-6 txt-orange'>{i18n.about2}</span>
								</h2>
								<p>
									{i18n.text1}
									<br />
									{i18n.text2}{' '}
								</p>
								<p>{i18n.text3}</p>
							</div>
							<div className='col-md-6 s'>
								<img src='images/map-bg-orange.jpg' alt='' />
							</div>
						</div>
					</div>
				</section>
				<section className=' mb-spacer-md '>
					<div className='container wide-tb-50 pb-0'>
						<div className='row d-flex align-items-center'>
							<div
								className='col col-12 col-lg-3 col-sm-6 wow slideInUp'
								data-wow-duration='0'
								data-wow-delay='0s'
							>
								<div className='counter-style-1 light-bg'>
									<p className='mb-1'>
										<i className='icofont-google-map'></i>
									</p>
									<span className='counter'>1</span>
									<div>{i18nk.text1}</div>
								</div>
							</div>
							<div
								className='col col-12 col-lg-3 col-sm-6 wow slideInUp'
								data-wow-duration='0'
								data-wow-delay='0.3s'
							>
								<div className='counter-style-1 light-bg'>
									<p className='mb-1'>
										<i className='icofont-globe'></i>
									</p>
									<span className='counter'>110</span>
									<span>+</span>
									<div>{i18nk.text2}</div>
								</div>
							</div>
							<div className='w-100 d-none d-sm-block d-lg-none spacer-60'></div>
							<div
								className='col col-12 col-lg-3 col-sm-6 wow slideInUp'
								data-wow-duration='0'
								data-wow-delay='0.6s'
							>
								<div className='counter-style-1 light-bg'>
									<p className='mb-1'>
										<i className='icofont-vehicle-delivery-van'></i>
									</p>
									<span className='counter'>20</span>
									<span>+</span>
									<div>{i18nk.text3}</div>
								</div>
							</div>
							<div
								className='col col-12 col-lg-3 col-sm-6 wow slideInUp'
								data-wow-duration='0'
								data-wow-delay='0.9s'
							>
								<div className='counter-style-1 light-bg'>
									<p className='mb-1'>
										<i className='icofont-umbrella-alt'></i>
									</p>
									<span className='counter'>2340</span>
									<div>{i18nk.text4}</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className='pos-rel bg-sky-blue wide-tb-100 '>
					<div className='container-fluid'>
						<div className='row align-items-center'>
							<div className='col-lg-5 col-12 p-0'>
								<img src='images/why-choose-us.jpg' className='w-100' alt='' />
							</div>
							<div className='col-lg-6 col-12'>
								<div className='p-5 about-whoose'>
									<h1 className='heading-main text-left mb-4'>
										<span>{i18nch.choose}</span>
										Alitrans
									</h1>
									<ul className='list-unstyled icons-listing theme-orange w-half mb-0'>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.title1}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.title2}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.text4}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.title3}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.text3}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.text2}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.title4}
										</li>
										<li className=''>
											<i className='icofont-check'></i>
											{i18nch.text5}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className='bg-light-gray pb-5 why-choose'>
					<div className='container pos-rel'>
						<div className='row'>
							<div className='col-sm-12'>
								<h1 className='heading-main'>
									<span>{i18ng.goodnes}</span>
									{i18ng.theme}
								</h1>
							</div>
							<div className='col-12 col-lg-4 '>
								<div className='icon-box-2'>
									<div className='media'>
										<div className='service-icon'>
											<i className='icofont-id'></i>
										</div>
										<div className='service-inner-content media-body'>
											<h4 className='h4-md'>{i18ng.title1}</h4>
											<p>{i18ng.text1}</p>
										</div>
									</div>
								</div>
							</div>
							<div className='col-12 col-lg-4 '>
								<div className='icon-box-2'>
									<div className='media'>
										<div className='service-icon'>
											<i className='icofont-live-support'></i>
										</div>
										<div className='service-inner-content media-body'>
											<h4 className='h4-md'>{i18ng.title2}</h4>
											<p>{i18ng.text2}</p>
										</div>
									</div>
								</div>
							</div>
							<div className='col-12 col-lg-4 '>
								<div className='icon-box-2'>
									<div className='media'>
										<div className='service-icon'>
											<i className='icofont-history'></i>
										</div>
										<div className='service-inner-content media-body'>
											<h4 className='h4-md'>{i18ng.title3}</h4>
											<p>{i18ng.text3}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className='wide-tb-100 bg-fixed clients-bg pos-rel'>
					<div className='bg-overlay blue opacity-80'></div>
					<div className='container'>
						<div className='row'>
							<div className='col-sm-12'>
								<h1 className='heading-main'>
									<span>{i18nr.clients}</span>
									{i18nr.clients2}
								</h1>
							</div>
						</div>
						<div className='row'>
							<div
								className='col-sm-12 col-md-6'
								style={{
									objectFit: 'contain',
								}}
							>
								<img
									style={{
										height: '100%',
										width: '100%',
									}}
									src='images/certificates/cer1.jpg'
									alt=''
								/>
							</div>
							<div
								className='col-sm-12 col-md-6'
								style={{
									objectFit: 'contain',
								}}
							>
								<img
									style={{
										height: '100%',
										width: '100%',
									}}
									src='images/certificates/cer2.jpg'
									alt=''
								/>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default Content;
