import { useAppContext } from "contexts/AppContext";
import REQUEST_DATA from "i18n/components/request/request.i18n";
import React, { useState } from "react";
import Forms from "./FormsRequest";

const RequestModal = () => {
  const { state, dispatch } = useAppContext();
  const i18n = REQUEST_DATA(state.lang);

  // const [coments, setComments] = useState([]);
  // const message = [];
  // const [value, setValue] = useState({
  //   name: "",
  //   number: "",
  //   email: "",
  //   comment: "",
  //   id: 0,
  // });
  // const handleChange = (e) => {
  //   setValue({
  //     ...value,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const handlePush = (e) => {
  //   e.preventDefault();
  //   message.push(value);
  //   console.log(message);
  //   setValue({
  //     name: "",
  //     number: "",
  //     email: "",
  //     comment: "",
  //     id: 0,
  //   });
  // };
  return (
    <>
      <div
        className="modal fade"
        id="request_popup"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered request_popup"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <section className="pos-rel bg-light-gray">
                <div className="container-fluid p-0">
                  <a
                  
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="icofont-close-line"></i>
                  </a>
                  <div
                    className="d-lg-flex justify-content-end no-gutters mb-spacer-md"
                    style={{
                      boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                    }}
                  >
                    <div className="col-12">
                      <div className="px-3 m-5">
                        <h2 className="h2-xl mb-4 fw-6">{i18n.request}</h2>
                        <Forms data={"about"} />
                        {/* <form
                          // action="#"
                          // method="post"
                          // noValidate="novalidate"
                          className="rounded-field"
                          onSubmit={handlePush}
                        >
                          <div className="form-row mb-4">
                            <div className="col">
                              <input
                                type="text"
                                value={value.name}
                                name="name"
                                className="form-control mb-3"
                                placeholder={i18n.pName}
                                onChange={handleChange}
                              />
                              <input
                                type="text"
                                value={value.email}
                                name="email"
                                className="form-control mb-3"
                                placeholder={i18n.pEmail}
                                onChange={handleChange}
                              />
                              <input
                                type="text"
                                value={value.number}
                                name="number"
                                className="form-control"
                                placeholder={i18n.pPhone}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col">
                              <textarea
                                rows="7"
                                value={value.comment}
                                name="comment"
                                placeholder={i18n.pMessage}
                                onChange={handleChange}
                                className="form-control"
                              ></textarea>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col d-flex justify-content-center pt-3">
                              <button
                                type="button"
                                className="form-btn btn-theme bg-orange"
                                onClick={handlePush}
                              >
                                {i18n.sendMessage}{" "}
                                <i className="icofont-rounded-right"></i>
                              </button>
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestModal;
