import React from "react";
import Header from "../../PagesHeader";
import Footer from "../../Footer";
import Content from "./Content";
import Breadcrumbs from "../../Breadcrumbs";
import EmailSubscribe from "../../EmailSubscribe";
import BackToTop from "../../BackToTop";
import SearchPopup from "../../SearchPopup";
import RequestModal from "../../RequestModal";
import BreadcrumbsData from "../../breadcrumbs.json";
import BREADCRUMBS_DATA from "i18n/components/breadcrumbs/breadcrumbs.i18n";
import { useAppContext } from "contexts/AppContext";

const Contact = () => {
  const { state, dispatch } = useAppContext();
  const i18n = BREADCRUMBS_DATA(state.lang);
  return (
    <>
      <Header />
      <Breadcrumbs title={i18n.contact} description={i18n.descContact} />
      <Content />
      <Footer />
      <RequestModal />
      <BackToTop />
    </>
  );
};
export default Contact;
