import { useAppContext } from 'contexts/AppContext';
import GET_LANG_DATA from 'i18n/header.i18';
import React from 'react';
import { CUT_STRINGS } from 'utils/cutString';
import Loader from './Loader';
const Header = () => {
	const { state, dispatch } = useAppContext();
	const lang = state.lang;
	const i18n = GET_LANG_DATA(state.lang);
	const CHANGE_LANG = (newLang) => {
		const newLanguage = CUT_STRINGS(newLang, 2);
		if (lang !== newLanguage) {
			dispatch({
				type: 'change_language',
				value: newLanguage,
			});
		}
	};
	return (
		<>
			<Loader />
			<header className='fixed-top header-fullpage bordered-nav wow fadeInDown'>
				<div className='top-bar-right d-flex align-items-center text-md-left'>
					<div className='container px-0'>
						<div className='row align-items-center'>
							<div className='col d-flex'>
								<div className='top-text'>
									<small className='txt-black'>{i18n.adress}</small>
									<p style={{ fontSize: '12px' }}>{i18n.adress2}</p>
								</div>
								<div className='top-text'>
									<small className='txt-black'>{i18n.email}</small>
									<a href='mailto:alitrans82@mail.ru'>alitrans82@mail.ru</a>
								</div>
								<div className='top-text'>
									<a href='tel:+998936661000'>
										<small className='txt-black'>{i18n.phone}</small>
										+99893 666 1000
									</a>
								</div>
							</div>
							<div className='col-md-auto'>
								<div className='dropdown d-inline-flex lang-toggle'>
									<a
										href='#'
										className='dropdown-toggle btn'
										data-toggle='dropdown'
										aria-haspopup='true'
										aria-expanded='false'
										data-hover='dropdown'
										data-animations='slideInUp slideInUp slideInUp slideInUp'
										style={{ width: '167px' }}
									>
										<img
											src={i18n.flag}
											alt=''
											className='dropdown-item-icon'
										/>
										<span className='d-inline-block d-lg-none'>US</span>
										<span className='d-none d-lg-inline-block'>
											{i18n.counter}
										</span>{' '}
										<i className='icofont-rounded-down'></i>
									</a>
									<div
										className='dropdown-menu dropdownhover-bottom dropdown-menu-right'
										role='menu'
									>
										{['English', 'Русский', 'Uzbekcha'].map((item, index) => (
											<a
												className={`dropdown-item ${
													CUT_STRINGS(item.toLowerCase(), 2) === lang
														? 'bg-info'
														: ''
												} `}
												key={index}
												style={{ cursor: 'pointer' }}
												onClick={() => CHANGE_LANG(item.toLowerCase())}
											>
												{item}
											</a>
										))}
									</div>
								</div>
								<div className='d-inline-flex request-btn ml-2'>
									<a
										className='btn-theme icon-left bg-orange no-shadow d-none d-lg-inline-block align-self-center'
										href='#'
										role='button'
										data-toggle='modal'
										data-target='#request_popup'
										style={{ width: '220px' }}
									>
										<i className='icofont-page'></i> {i18n.request}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<nav className='navbar navbar-expand-lg bg-transparent'>
					<div className='container text-nowrap bdr-nav px-0'>
						<div className='d-flex mr-auto'>
							<a className='navbar-brand rounded-bottom light-bg' href='/'>
								<img
									src='images/trans.png'
									alt=''
									style={{
										borderRadius: '25px',
									}}
								/>
							</a>
						</div>

						<button
							className='navbar-toggler x collapsed'
							type='button'
							data-toggle='collapse'
							data-target='#navbarCollapse'
							aria-controls='navbarCollapse'
							aria-expanded='false'
							aria-label='Toggle navigation'
						>
							<span className='icon-bar'></span>
							<span className='icon-bar'></span>
							<span className='icon-bar'></span>
						</button>
						<div
							className='collapse navbar-collapse'
							id='navbarCollapse'
							data-hover='dropdown'
							data-animations='slideInUp slideInUp slideInUp slideInUp'
						>
							<ul className='navbar-nav ml-auto'>
								<li className='nav-item'>
									<a className='nav-link' href='/'>
										{i18n.home}
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='about'>
										{i18n.about}
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='contact'>
										{i18n.contact}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
		</>
	);
};

export default Header;
