import React from "react";
import Header from "../PagesHeader";
import Footer from "../Footer";
import Content from "./Content";
import Breadcrumbs from "../Breadcrumbs";
import EmailSubscribe from "../EmailSubscribe";
import BackToTop from "../BackToTop";
import SearchPopup from "../SearchPopup";
import VideoBox from "./VideoBox";
import RequestModal from "../RequestModal";
import BreadcrumbsData from "../breadcrumbs.json";
import GET_LANG_DATA from "i18n/header.i18";
import { useAppContext } from "contexts/AppContext";
import BREADCRUMBS_DATA from "i18n/components/breadcrumbs/breadcrumbs.i18n";

const About = () => {
  const { state, dispatch } = useAppContext();
  const i18n = GET_LANG_DATA(state.lang);
  const i18nb = BREADCRUMBS_DATA(state.lang);
  return (
    <>
      <Header />
      <Breadcrumbs title={i18nb.about} description={i18nb.descAbout} />
      <Content />
      <Footer />
      <VideoBox />
      <RequestModal />
      <BackToTop />
    </>
  );
};
export default About;
