const SLIDER_DATA = (lang) => {
  const data = {
    uz: {
      weAre: "Biz Alitrans",
      weAre2: "kompaniyasimiz",
      from: "O'zbekistondan",
      text1:
        "Mahsulotlaringizni o'z vaqtida sof xavfsizlik bilan yetkazib beramiz.",
      title1: `Har qanday`,
      title2: `to'siqlarga tayyor`,
      text21: "Kompaniyamiz avtotransport",
      text22: "va yuklarni yetkazib berish",
      text23: "xizmatlarini ko'rsatuvchi ",
      text24: "yetakchi kompaniyalardan biridir.",
      btnText1: "Ko'proq ma'lumot olish",
      btnText2: "KO'PROQ O'QISH",
    },
    ru: {
      weAre: "Мы Компания",
      weAre2: "Алитранс",
      from: "Из Узбекистана",
      text1: `Мы доставляем вашу продукцию вовремя с полной безопасностью.`,
      title1: `Готов к любым`,
      title2: `препятствиям`,
      text21: "Наша компания является",
      text22: "одной из ведущих компаний,",
      text23: "предоставляющих автотранспортные",
      text24: "услуги и услуги по доставке грузов.",
      btnText1: "Узнать больше",
      btnText2: "ПРОЧИТАЙТЕ БОЛЬШЕ",
    },
    en: {
      weAre: "   We Are",
      weAre2: "Company",
      weAre3: "Alitrans",
      from: "From Uzbekistan",
      text1: "We deliver your products on time with pure safety.",
      title1: `Ready For`,
      title2: `Any  Obstacle`,
      text21: "Our company is one of the",
      text22: "leading companies providing",
      text23: "motor transport and cargo",
      text24: "delivery services.",
      btnText1: "Learn More",
      btnText2: "READ MORE",
    },
  };
  return data[lang] || data.ru;
};
export default SLIDER_DATA;
