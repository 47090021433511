import Forms from 'components/FormsRequest';
import { useAppContext } from 'contexts/AppContext';
import REQUEST_DATA from 'i18n/components/request/request.i18n';
import GET_LANG_DATA from 'i18n/header.i18';
import React from 'react';

const Content = () => {
	const { state, dispatch } = useAppContext();
	const i18n = REQUEST_DATA(state.lang);
	const i18na = GET_LANG_DATA(state.lang);
	return (
		<>
			<main id='body-content'>
				<section className='wide-tb-80 contact-full-shadow'>
					<div className='container'>
						<div className='contact-map-bg'>
							<img src='images/map-bg.png' alt='' />
						</div>
						<div className='row justify-content-between'>
							<div className='col-md-6 col-sm-12 col-lg-4 s'>
								<div
									className='contact-detail-shadow d-flex align-items-center flex-column justify-content-center'
									style={{ height: '189px' }}
								>
									<h4>{i18n.counter}</h4>
									<div className='d-flex align-items-center items justify-content-center'>
										<i className='icofont-google-map'></i>{' '}
										<a
											href='https://www.google.com/maps?q=Логистика+Ангрен&ll=41.026680,70.056880&z=16'
											target='_blank'
											className=''
										>
											{i18na.adress2}
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-sm-12 col-lg-4 s'>
								<div
									className='contact-detail-shadow d-flex align-items-center flex-column justify-content-center'
									style={{ height: '189px' }}
								>
									<h4>{i18n.phoneName}</h4>
									<div className='d-flex align-items-center items justify-content-center'>
										<i className='icofont-phone'></i>{' '}
										<a href='tel:+998936661000'>+998 93 666 1000</a>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-sm-12 col-lg-4 s'>
								<div
									className='contact-detail-shadow d-flex align-items-center flex-column justify-content-center'
									style={{ height: '189px' }}
								>
									<h4>{i18n.emailName}</h4>
									<div className='text-nowrap d-flex align-items-center items justify-content-center'>
										<i className='icofont-email'></i>{' '}
										<a href='mailto:alitrans82@mail.ru'>alitrans82@mail.ru</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='wide-tb-100 bg-light-gray pt-0'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-12 col-sm-12 col-lg-8 offset-lg-2 '>
								<div className='free-quote-form contact-page'>
									<h1 className='heading-main mb-4'>{i18n.getInTouch}</h1>
									<Forms data={'contact'} />
									{/* <form
                    action="#"
                    method="post"
                    id="contactusForm"
                    noValidate="novalidate"
                    className="col rounded-field"
                  >
                    <div className="form-row mb-4">
                      <div className="col">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder={i18n.pName}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          name="number"
                          id="email"
                          className="form-control"
                          placeholder={i18n.pPhone}
                        />
                      </div>
                    </div>
                    <div className="form-row mb-4">
                      <div className="col">
                        <textarea
                          rows="10"
                          name="cment"
                          id="cment"
                          placeholder="Message"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-row text-center">
                      <button
                        name="contactForm"
                        id="contactForm"
                        type="button"
                        className="form-btn mx-auto btn-theme bg-orange"
                      >
                        {i18n.btnText} <i className="icofont-rounded-right"></i>
                      </button>
                    </div>
                  </form> */}
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='map-bg'>
					<div id='map-holder' className='pos-rel'>
						<div>
							<iframe
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1504.96731123333!2d70.05579906715273!3d41.02668624946594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38afebbee6870f37%3A0xd426c7d70c32b714!2sAlitrans%20Logistics%20company!5e0!3m2!1sen!2s!4v1651319687320!5m2!1sen!2s'
								width='600'
								height='450'
								style={{ border: '0', width: '100%' }}
								allowfullscreen=''
								loading='lazy'
								referrerpolicy='no-referrer-when-downgrade'
							></iframe>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default Content;
