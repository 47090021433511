const GOODNES_DATA = (lang) => {
  const data = {
    uz: {
      goodnes: "Bizning yaxshiligimiz",
      theme: "Bizni nima o'ziga xos qiladi",
      text: `Bizning ishonchliligimiz, samaradorligimiz va yuqori darajadagi xizmat ko'rsatishimiz tashqi savdo kompaniyalari bilan ko'p yillik hamkorlik, amalga oshirilgan ko'plab yuklar va hamkorlarimizning yuksak bahosi bilan isbotlangan. `,
      title1: "Ishonchli va mas'uliyatli",
      title2: "Mijozlarni qo'llab-quvvatlash",
      title3: "Ishonchlilik va punktuallik",
      text1:
        "Barcha transport turlarining afzalliklarini birlashtirgan holda, biz etkazib berish tezligi va narx bo'yicha sizning talablaringizni qondiradigan xalqaro yuklarni loyihalashtiramiz va amalga oshiramiz.",
      text2:
        "Bizning ekspeditorlik kompaniyamiz yuk mashinasi yordamida yuk tashishning har bir bosqichini samarali nazorat qilib, xalqaro multimodal tashishning keng spektrini taklif etadi.",
      text3:
        "Kompaniyamiz avtotransport va yuklarni yetkazib berish xizmatlarini ko'rsatuvchi MDHdagi yetakchi kompaniyalardan biridir. Biz standart yuklarni, guruhlash, tezkor yuklarni katta o'lchamdagi va og'ir yuklarni etkazib berishni amalga oshiramiz.",
    },
    ru: {
      goodnes: "Наша доброта",
      theme: "Что делает нас особенными",
      text: `Наша надежность, оперативность и высокий уровень сервиса доказаны многими годами работы с внешнеторговыми компаниями, многими выполненными перевозками и высокой оценкой наших партнеров.`,
      title1: "Надежный и ответственный",
      title2: "Служба поддержки",
      title3: "Надежность и пунктуальность",
      text1:
        "Сочетая преимущества всех видов транспорта, мы проектируем и осуществляем международные перевозки, отвечающие вашим требованиям по скорости доставки и цене.",
      text2:
        "Наша транспортно-экспедиторская компания предлагает широкий спектр международных мультимодальных перевозок, эффективно контролируя каждый этап перевозки груза с использованием грузового автомобиля.",
      text3:
        "Наша компания одна из ведущих компаний в СНГ, оказывающих услуги автоперевозки и доставки грузов. Мы осуществляем доставку стандартных грузов,  требующих специальных условий транспортировки, негабаритных и тяжеловесных отправок.",
    },
    en: {
      goodnes: "Our Goodness",
      theme: "What Make Us Special",
      text: `Our reliability, efficiency and high level of service have been proven by many years of work with foreign trade companies, many transportations performed and the high appreciation of our partners.`,
      title1: "Trusted and responsible",
      title2: "Customer Support",
      title3: "Reliability & Punctuality",
      text1:
        "Combining the advantages of all modes of transport, we design and implement international shipments that meet your requirements for speed of delivery and price.",
      text2:
        "Our freight forwarding company offers a wide range of international multimodal transportation, effectively controlling every stage of cargo transportation using a truck.",
      text3:
        "We carry out the delivery of standard cargo,  dangerous goods requiring special transportation conditions, oversized and heavy shipments.",
    },
  };
  return data[lang] || data.ru;
};
export default GOODNES_DATA;
