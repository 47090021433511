const INFOCOST_DATA = (lang) => {
  const data = {
    uz: {
      text1: "Bizning manzillarimiz",
      text2: "O'zbekiston bo'ylab mijozlar",
      text3: "Egalikdagi Avtomobillar",
      text4: "Tonna tashildi",
    },
    ru: {
      text1: "Наши местоположения",
      text2: "Клиенты по всему Узбекистане",
      text3: "Собственные автомобили",
      text4: "тоннa перевезено",
    },
    en: {
      text1: "Our Locations",
      text2: "Clients all over Uzbekistan",
      text3: "Owned Vehicles",
      text4: "Tonnes Transported",
    },
  };
  return data[lang] || data.ru;
};
export default INFOCOST_DATA;
