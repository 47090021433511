import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './about';
import Contact from './contact/contactus';
import HomeOne from './home/homeOne';
import NotFound from './pages/notfound';

function MainNavigation() {
	return (
		<Router>
			<Switch>
				{/** Home */}
				<Route path='/' exact={true} component={HomeOne} />
				{/** Pages */}
				<Route path='/notfound' exact={true} component={NotFound} />
				{/** About Services Contact */}
				<Route path='/about' exact={true} component={About} />
				<Route path='/contact' exact={true} component={Contact} />

				<Route component={NotFound} />
			</Switch>
		</Router>
	);
}

export default MainNavigation;
