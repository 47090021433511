const REQUEST_DATA = (lang) => {
  const data = {
    uz: {
      request: "So'rov",
      free: "Bepul taklif",
      title: "Yuborish",
      sendMessage: "Xabar yuborish",
      btnText: "Yuborish",
      counter: "Uzbekiston",
      phoneName: "Telefon raqami",
      emailName: "e-pochta manzilimiz",
      getInTouch: "Aloqa qiling",
      pEmail: "Sizning e-pochta manzilingiz",
      pMessage: "Xabar",
      pPhone: "Telefon raqami",
      pName: "Ismingiz",
      clients: "BIZNING",
      clients2: "Sertifikat",
      reqyestText: `Siz tarqatish yoki bajarishni talab qilasizmi, aniqlangan
      yuk tashish, yoki to'liq ta'minot zanjiri yechim, biz
      siz uchun shu yerdamiz.`,
      fruit: "mevalar",
      technics: "texnika",
      wood: "yog'och",
      machine: "mashina",
      pipe: "quvur",
      instruments: "asboblar",
      water: "suv",
      car: "avtomobil",
      transType: "Yuk mashinasi",
      freight: "Yuk tashish turi",
      transport: "Transport turi",
    },
    ru: {
      request: "Запрос",
      free: "Бесплатная цитата",
      title: "Отправить",
      sendMessage: "Отправить сообщение",
      btnText: "Отправить",
      counter: "Узбекистан",
      phoneName: "Телефонный номер",
      emailName: "наш адрес электронной почты",
      getInTouch: "Связаться",
      pEmail: "Ваш адрес электронной почты",
      pMessage: "Сообщение",
      pPhone: "Телефонный номер",
      pName: "Твое имя",
      clients: "НАШИ",
      clients2: "Сертификат",
      reqyestText: `Требуете ли вы распространения или выполнения, определяется
      экспедирование грузов или полное решение цепочки поставок, мы
      здесь для вас.`,
      fruit: "фрукты",
      technics: "техника",
      wood: "дерево",
      machine: "станок",
      pipe: "труба",
      instruments: "инструменты",
      water: "вода",
      car: "автомобиль",
      transType: "Грузовая машина",
      freight: "Тип груза",
      transport: "Тип транспорта",
    },
    en: {
      request: "Request",
      free: "Free Quote",
      title: "Send",
      sendMessage: "Send Message",
      btnText: "Submit Now",
      counter: "Uzbekistan",
      phoneName: "Phone number",
      emailName: "our email address",
      getInTouch: "Get in touch",
      pEmail: "Your Email Address",
      pMessage: "Message",
      pPhone: "Phone Number",
      pName: "Your Name",
      clients: "SOME OF OUR",
      clients2: "Certificate",
      reqyestText: `Whether you require distribution or fulfillment, defined
      freight forwarding, or a complete supply chain solution, we
      are here for you.`,
      fruit: "fruists",
      technics: "technics",
      wood: "wood",
      machine: "machine",
      pipe: "pipe",
      instruments: "instruments",
      water: "water",
      car: "car",
      transType: "Truck",
      freight: "Type of freight",
      transport: "Transport Type",
    },
  };
  return data[lang] || data.ru;
};
export default REQUEST_DATA;
