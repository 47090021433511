const BREADCRUMBS_DATA = (lang) => {
  const data = {
    uz: {
      about: "Biz haqimizda",
      descAbout: "Alitrans eng arzon va ishonchli kompaniya hisoblanadi.",
      contact: "Bog'lanish",
      descContact:
        "Bizning global tarmog'imiz va tezlashtirilgan yuk bo'yicha mutaxassislardan iborat to'liq xizmat ko'rsatish jamoasi yordam berishga tayyor.",
    },
    ru: {
      about: "О нас",
      descAbout: "Alitrans — самая дешевая и надежная компания.",
      contact: "Связаться с нами",
      descContact:
        "Наша глобальная сеть и команда специалистов по ускоренным перевозкам с полным спектром услуг готовы помочь.",
    },
    en: {
      about: "About Us",
      descAbout: "Alitrans is the cheapest and most reliable company.",
      contact: "Contact Us",
      descContact:
        "Our global network and a full service team of accelerated freight specialists are ready to help.",
    },
  };
  return data[lang] || data.ru;
};
export default BREADCRUMBS_DATA;
