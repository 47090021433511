const CHOOSE_DATA = (lang) => {
  const data = {
    uz: {
      features: "Bizning xususiyatlarimiz",
      choose: "Nima uchun bizni tanlaysiz",
      title1: "Uzbekiston bo'ylab joylar",
      text1: `Butun dunyo bo'ylab yetkazib berish. Biz dunyoning istalgan nuqtasiga yetkazib beramiz. Jamoa ko'p yillik tajribaga ega`,
      title2: "Sifat va majburiyat",
      text2: `Vaqt va pulni tejang, shuningdek, telefon orqali tovarlarning holatini bilib oling.`,
      title3: "24/7 mijozlarni qo‘llab-quvvatlash",
      text3: `Ko‘p yillik tajribaga asoslanib, mas’uliyat xodimlarimiz uchun odat tusiga kirgan.`,
      title4: "100% xavfsiz yetkazib berish",
      text5: "Javobgarlikga amal qilamiz",
      text4: `Xavfsizlik va ishonchlilik.`,
    },
    ru: {
      features: "Наши особенности",
      choose: "Почему выбрали нас",
      title1: "Места по Узбекистану",
      text1: `Доставка по всему миру. Доставляем в любую точку мира. Команда имеет многолетний опыт`,
      title2: "Качество и приверженность",
      text2: `сэкономить время и деньги, а так же узнать статус товара по телефону.`,
      title3: "Круглосуточная поддержка клиентов",
      text3: `Исходя из многолетнего опыта, ответственность стала для наших сотрудников привычкой.`,
      title4: "100% безопасная доставка",
      text5: "Мы будем привлечены к ответственности",
      text4: `Безопасность и надежность.`,
    },
    en: {
      features: "Our Features",
      choose: "Why Choose Us",
      title1: "Places across Uzbekistan",
      text1: `Worldwide delivery. We deliver anywhere in the world. The team has many years of experience`,
      title2: "Quality and Commitment",
      text2: `Save time and money, as well as find out the status of goods by phone.`,
      title3: "24/7 Customer Support",
      text3: `Based on many years of experience, responsibility has become a habit for our employees.`,
      title4: "100% Safe Delivery",
      text5: "We will be held accountable",
      text4: `Security and reliability.`,
    },
  };
  return data[lang] || data.ru;
};
export default CHOOSE_DATA;
