import React, { useEffect } from 'react';
import MainNavigation from './components/MainNavigation';
import { AppWrapper } from './contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
	useEffect(() => {
		const scriptmasonary = document.createElement('script');
		scriptmasonary.src = '/js/masonary-custom.js';
		scriptmasonary.async = true;
		document.head.appendChild(scriptmasonary);
	});

	return (
		<>
			<AppWrapper>
				<MainNavigation />
			</AppWrapper>
			<ToastContainer />
		</>
	);
};

export default App;
